
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import coreStore from '@/store/CoreStore';
import router from '@/router';
import CoreRouteTypes from '@/router/types';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import LanguageService from '@/services/LanguageService';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    isDisabled: boolean;
    selectedLocationId: number;
};

export default defineComponent({
    name: 'location-selector-screen',
    components: { BFormSelect },
    setup() {
        const { profileStore } = coreStore.getInstance();

        const state = reactive<State>({
            isDisabled: false,
            selectedLocationId: 0,
        });

        const locationOptions = computed(() => [{ id: 0, name: getTranslation('core.common.selectLocation').toUpperCase(), disabled: true }, ...profileStore.locations]);

        async function setCurrentLocation(locationId: number) {
            await profileStore.setUserLocation(locationId);
            LanguageService.initDefaultLanguage();
            await router.push({ name: CoreRouteTypes.HOME });
        }

        onBeforeMount(async () => {
            if (profileStore.locations.length === 1) {
                await setCurrentLocation(profileStore.locations[0].id);
            }
        });

        async function doSetCurrentLocation() {
            state.isDisabled = true;
            if (state.selectedLocationId) {
                await setCurrentLocation(state.selectedLocationId);
            }

            state.isDisabled = false;
        }

        return {
            state,
            doSetCurrentLocation,
            profileStore,
            locationOptions,
            getTitleCaseTranslation,
        };
    },
});
